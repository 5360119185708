import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from "../pages/projectPage.module.scss"
import Layout from "../components/layout"
import Head from "../components/head"
import { motion } from "framer-motion"

export const query = graphql`
  query($slug: String!) {
    contentfulProjectPage(slug: { eq: $slug }) {
      title
      stack
      id
      description {
        json
      }
      screenshot {
        file {
          url
        }
      }
      links
      challenges {
        json
      }
      takeaways {
        json
      }
      slug
      features {
        json
      }
    }
  }
`

const Project = props => {
  //   const options = {
  //     renderNode: {
  //       "embedded-asset-block": node => {
  //         const alt = node.data.target.fields.title["en-US"]
  //         const url = node.data.target.fields.file["en-US"].url
  //         return <img alt={alt} src={url} />
  //       },
  //     },
  //   }
  return (
    <Layout>
      <Head title={props.data.contentfulProjectPage.title} />
      <div className={styles.goBack}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <polygon points="3.828 9 9.899 2.929 8.485 1.515 0 10 .707 10.707 8.485 18.485 9.899 17.071 3.828 11 20 11 20 9 3.828 9" />
        </svg>
        <Link to="/" className={styles.goBackLink}>
          Go back
        </Link>
      </div>
      <motion.div className={styles.container}  initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
        <h1 className={styles.projectTitle}>
          {props.data.contentfulProjectPage.title}
        </h1>

        <div className={styles.tableGrid}>
          {/* <div className={styles.linksContainer}>
              <div className={styles.btnView}>
                <a href={props.data.contentfulProjectPage.links[1]} target="_blank" rel="noreferrer">
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".0s" }}
                    >
                      v
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".02s" }}
                    >
                      i
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".04s" }}
                    >
                      s
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".06s" }}
                    >
                      i
                    </span>
                  </p>

                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".08s", marginRight: "6px" }}
                    >
                      t
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".12s" }}
                    >
                      s
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".14s" }}
                    >
                      i
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".16s" }}
                    >
                      t
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".18s" }}
                    >
                      e
                    </span>
                  </p>

                  <p className={styles.endArrow}>
                    <svg
                      className={`${styles.tra} ss`}
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      style={{ transitionDelay: ".1s" }}
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#ffffff"
                        strokeWidth="3.04"
                        transform="rotate(45 16.036 18.086)"
                      >
                        <polyline points="0 11.647 10.965 .682 22 11.717"></polyline>
                        <path d="M10.9240122,0.607902736 L10.9240122,33.064978"></path>
                      </g>
                    </svg>
                  </p>
                </a>
              </div>
              <div className={styles.btnView}>
                <a href={props.data.contentfulProjectPage.links[0]} target="_blank" rel="noreferrer">
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".0s" }}
                    >
                      v
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".02s" }}
                    >
                      i
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".04s" }}
                    >
                      e
                    </span>
                  </p>

                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".06s", marginRight: "6px" }}
                    >
                      w
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".12s" }}
                    >
                      r
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".14s" }}
                    >
                      e
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".16s" }}
                    >
                      p
                    </span>
                  </p>
                  <p>
                    <span
                      className={styles.tra}
                      style={{ transitionDelay: ".18s" }}
                    >
                      o
                    </span>
                  </p>

                  <p className={styles.endArrow}>
                    <svg
                      className={`${styles.tra} ss`}
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      style={{ transitionDelay: ".1s" }}
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#ffffff"
                        strokeWidth="3.04"
                        transform="rotate(45 16.036 18.086)"
                      >
                        <polyline points="0 11.647 10.965 .682 22 11.717"></polyline>
                        <path d="M10.9240122,0.607902736 L10.9240122,33.064978"></path>
                      </g>
                    </svg>
                  </p>
                </a>
              </div>
            </div> */}
          <div className={styles.descContainer}>
            {/* <h1 className={styles.projectTitle}>
            {props.data.contentfulProjectPage.title}
          </h1> */}
            <div>
              {documentToReactComponents(
                props.data.contentfulProjectPage.features.json
              )}
            </div>
            { props.data.contentfulProjectPage.stack ? (
  <span className={styles.label}>Built with:</span>
            ) : ''
            }
          
            <span className={styles.value}>
              <ul>
                {props.data.contentfulProjectPage.stack ? props.data.contentfulProjectPage.stack.map(s => (
                  <li>{s}</li>
                )) : ''}
              </ul>
            </span>
          </div>
          <div className={styles.introContainer}>
            <div>
              <a
                href={props.data.contentfulProjectPage.links[0]}
                target="_blank"
                rel="noreferrer"
              >
                Visit Site
              </a>
              { props.data.contentfulProjectPage.links[1] ? (
                <a
                href={props.data.contentfulProjectPage.links[1]}
                target="_blank"
                rel="noreferrer"
              >
                View Repo
              </a> ) : ''
            }
            
            </div>
          </div>
        </div>

        <div className={styles.mainContent}>
          <div className={styles.imageContainer}>
            <div class={styles.col}>
              <img
                src={props.data.contentfulProjectPage.screenshot[0].file.url}
                alt={props.data.contentfulProjectPage.title}
              ></img>
            </div>
            <div class={styles.col}>
              <img
                src={props.data.contentfulProjectPage.screenshot[1].file.url}
                alt={props.data.contentfulProjectPage.title}
              ></img>
            </div>
          </div>
          <div className={styles.descriptionContainer}>
            <h4 className={styles.subHeading}>Overview</h4>
            {documentToReactComponents(
              props.data.contentfulProjectPage.description.json
            )}
          </div>

          <div className={styles.imageContainer}>
            
          </div>
        </div>
      </motion.div>
    </Layout>
  )
}

export default Project
